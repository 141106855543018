import { I18nOptions, createI18n } from 'vue-i18n';

import af from '@/locales/af.json';
import sq from '@/locales/sq.json';
import ar from '@/locales/ar.json';
import bn from '@/locales/bn.json';
import km from '@/locales/km.json';
import ca from '@/locales/ca.json';
import zh_CN from '@/locales/zh-CN.json';
import zh_TW from '@/locales/zh-TW.json';
import hr from '@/locales/hr.json';
import cs from '@/locales/cs.json';
import da from '@/locales/da.json';
import nl from '@/locales/nl.json';
import et from '@/locales/et.json';
import fi from '@/locales/fi.json';
import fr from '@/locales/fr.json';
import de from '@/locales/de.json';
import el from '@/locales/el.json';
import gu from '@/locales/gu.json';
import he from '@/locales/he.json';
import hi from '@/locales/hi.json';
import hu from '@/locales/hu.json';
import id from '@/locales/id.json';
import ga from '@/locales/ga.json';
import it from '@/locales/it.json';
import ja from '@/locales/ja.json';
import jv from '@/locales/jv.json';
import kn from '@/locales/kn.json';
import kmr from '@/locales/kmr.json';
import ko from '@/locales/ko.json';
import lv from '@/locales/lv.json';
import lt from '@/locales/lt.json';
import ms from '@/locales/ms.json';
import mr from '@/locales/mr.json';
import ne from '@/locales/ne.json';
import nb from '@/locales/nb.json';
import ps from '@/locales/ps.json';
import fa from '@/locales/fa.json';
import pl from '@/locales/pl.json';
import pt from '@/locales/pt.json';
import pa from '@/locales/pa.json';
import ro from '@/locales/ro.json';
import ru from '@/locales/ru.json';
import sr from '@/locales/sr.json';
import sk from '@/locales/sk.json';
import sl from '@/locales/sl.json';
import es from '@/locales/es.json';
import sw from '@/locales/sw.json';
import sv from '@/locales/sv.json';
import tl from '@/locales/tl.json';
import ta from '@/locales/ta.json';
import te from '@/locales/te.json';
import th from '@/locales/th.json';
import tr from '@/locales/tr.json';
import uk from '@/locales/uk.json';
import ur from '@/locales/ur.json';
import uz from '@/locales/uz.json';
import vi from '@/locales/vi.json';
import cy from '@/locales/cy.json';
import en from '@/locales/en.json';

export type MessageSchema = typeof en;

af satisfies MessageSchema;
sq satisfies MessageSchema;
ar satisfies MessageSchema;
bn satisfies MessageSchema;
km satisfies MessageSchema;
ca satisfies MessageSchema;
zh_CN satisfies MessageSchema;
zh_TW satisfies MessageSchema;
hr satisfies MessageSchema;
cs satisfies MessageSchema;
da satisfies MessageSchema;
nl satisfies MessageSchema;
et satisfies MessageSchema;
fi satisfies MessageSchema;
fr satisfies MessageSchema;
de satisfies MessageSchema;
el satisfies MessageSchema;
gu satisfies MessageSchema;
he satisfies MessageSchema;
hi satisfies MessageSchema;
hu satisfies MessageSchema;
id satisfies MessageSchema;
ga satisfies MessageSchema;
it satisfies MessageSchema;
ja satisfies MessageSchema;
jv satisfies MessageSchema;
kn satisfies MessageSchema;
kmr satisfies MessageSchema;
ko satisfies MessageSchema;
lv satisfies MessageSchema;
lt satisfies MessageSchema;
ms satisfies MessageSchema;
mr satisfies MessageSchema;
ne satisfies MessageSchema;
nb satisfies MessageSchema;
ps satisfies MessageSchema;
fa satisfies MessageSchema;
pl satisfies MessageSchema;
pt satisfies MessageSchema;
pa satisfies MessageSchema;
ro satisfies MessageSchema;
ru satisfies MessageSchema;
sr satisfies MessageSchema;
sk satisfies MessageSchema;
sl satisfies MessageSchema;
es satisfies MessageSchema;
sw satisfies MessageSchema;
sv satisfies MessageSchema;
tl satisfies MessageSchema;
ta satisfies MessageSchema;
te satisfies MessageSchema;
th satisfies MessageSchema;
tr satisfies MessageSchema;
uk satisfies MessageSchema;
ur satisfies MessageSchema;
uz satisfies MessageSchema;
vi satisfies MessageSchema;
cy satisfies MessageSchema;
en satisfies MessageSchema;

export const i18nOptions: I18nOptions = {
  allowComposition: true,
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    af,
    sq,
    ar,
    bn,
    km,
    ca,
    zh_CN,
    zh_TW,
    hr,
    cs,
    da,
    nl,
    et,
    fi,
    fr,
    de,
    el,
    gu,
    he,
    hi,
    hu,
    id,
    ga,
    it,
    ja,
    jv,
    kn,
    kmr,
    ko,
    lv,
    lt,
    ms,
    mr,
    ne,
    nb,
    ps,
    fa,
    pl,
    pt,
    pa,
    ro,
    ru,
    sr,
    sk,
    sl,
    es,
    sw,
    sv,
    tl,
    ta,
    te,
    th,
    tr,
    uk,
    ur,
    uz,
    vi,
    cy,
    en,
  },
};

export const i18n = createI18n(i18nOptions);

declare module 'vue-i18n' {
  // define the locale messages schema
  export interface DefineLocaleMessage extends MessageSchema {}
}

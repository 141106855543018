import { TierName } from '@opinionx/tier';
import ApiService, { Params } from '../ApiService';
import { Workspace } from '@/stores/workspace';

export default {
  create(payload: any, params?: Params) {
    const config = {
      params,
    };
    return ApiService.post(`/workspaces`, payload, config);
  },
  findOne(id: string) {
    return ApiService.get(`/workspaces/${id}`);
  },
  findAll(params: Params) {
    const config = {
      params,
    };
    return ApiService.get(`/workspaces`, config);
  },
  findAllDetailed(params: Params) {
    const config = {
      params,
    };
    return ApiService.get(`/workspaces/detailed`, config);
  },
  overview(params: Params) {
    const config = {
      params,
    };
    return ApiService.get(`/workspaces/overview`, config);
  },
  activatedOverview(params: Params) {
    const config = {
      params,
    };
    return ApiService.get(`/workspaces/activated-overview`, config);
  },
  activatedOverviewSurveys(params: Params) {
    const config = {
      params,
    };
    return ApiService.get(`/workspaces/activated-overview/surveys`, config);
  },
  update(id: string, payload: any) {
    return ApiService.patch(`/workspaces/${id}`, payload);
  },
  remove(id: string) {
    return ApiService.delete(`/workspaces/${id}`);
  },
  addMember(id: string, payload: any) {
    return ApiService.post(`/workspaces/${id}/members`, payload);
  },
  updateMember(id: string, memberId: string, payload: any) {
    return ApiService.patch(`/workspaces/${id}/members/${memberId}`, payload);
  },
  removeMember(id: string, memberId: string) {
    return ApiService.delete(`/workspaces/${id}/members/${memberId}`);
  },
  addMemberByShareableLink(shareableLinkId: string) {
    return ApiService.patch(`/workspaces/workspace-invite/${shareableLinkId}`);
  },
  findOneByShareableLink(shareableLinkId: string) {
    return ApiService.get(`/workspaces/workspace-invite/${shareableLinkId}`);
  },
  addDomain(workspaceId: string, payload: any, params?: Params) {
    const config = {
      params,
    };
    return ApiService.post(
      `/workspaces/${workspaceId}/domains`,
      payload,
      config,
    );
  },
  removeDomain(workspaceId: string, domainId: string) {
    return ApiService.delete(`/workspaces/${workspaceId}/domains/${domainId}`);
  },
  findAllByDomain(domainId: string) {
    return ApiService.get(`/workspaces/domains/${domainId}`);
  },
  validateDomain(
    workspaceId: string,
    domainId: string,
    payload: any,
    params: any,
  ) {
    const config = {
      params,
      skipAuthRefresh: true,
    };
    return ApiService.patch(
      `/workspaces/${workspaceId}/domains/${domainId}/validated`,
      payload,
      config,
    );
  },
  sendDomainValidation(workspaceId: string, domainId: string) {
    return ApiService.post(
      `/workspaces/${workspaceId}/domains/${domainId}/send-validation`,
    );
  },
  checkDomainEligible(workspaceId: string, domain: string) {
    return ApiService.get<boolean>(
      `/workspaces/${workspaceId}/domains/eligible/${domain}`,
    );
  },
  upgrade(workspaceId: string, params: Params & { tierName: TierName }) {
    const config = {
      params,
    };
    return ApiService.patch<Workspace>(
      `/workspaces/${workspaceId}/upgrade`,
      {},
      config,
    );
  },
  upgradeSurvey(id: string, params: { tierName: TierName; surveyId: string }) {
    const config = {
      params,
    };
    return ApiService.post(`/workspaces/${id}/upgrade-survey`, {}, config);
  },
};
